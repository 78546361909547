:root {
  --secondary: #7c8db5;
  --border: #e6edff;
  --graphBlue: #3c43f3;
  --darkGreen: #086c4c;
  --lightGreen: #d2e4cc;
  --orange: #ff9500;
  --arrowGreen: #51a83e;

  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  min-width: 600px;
  background: #f5f5f7 !important;
  margin-bottom: 50px;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

h1 {
  font-size: 2.5rem !important;
  font-weight: 500 !important;
  margin-bottom: 1rem !important;
}

h2 {
  font-size: 2rem !important;
  font-weight: 500 !important;
  margin-bottom: 1rem !important;
}

h3 {
  font-size: 1.75rem !important;
  font-weight: 500 !important;
  margin-bottom: 1rem !important;
}

h4 {
  font-size: 1.5rem !important;
  font-weight: 500 !important;
  margin-bottom: 1rem !important;
}

h5 {
  font-size: 1.5rem !important;
  font-weight: 500 !important;
  margin-bottom: 1rem !important;
}

h6 {
  font-size: 1.25rem !important;
  font-weight: 500 !important;
  margin-bottom: 1rem !important;
}

.heading3 {
  color: var(--darkGreen);
  font-family: "poppins";
  font-size: 28px;
  margin-block: 0;
  padding-bottom: 10px;
}

.p-progressbar-label {
  margin-left: 20px;
  width: 100%;
}

.p-progressbar-determinate .p-progressbar-value-animate {
  overflow: visible !important;
}

.address-selector > .p-dropdown {
  background-color: transparent !important;
  border: none !important;
}
